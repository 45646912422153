<template>
  <div>
    <BaseBreadcrumb :title="'Matriz Encuestas Asignadas'"></BaseBreadcrumb>
    <div style="padding-top: 70px;">
      <v-card class="elevation-4 px-5 py-5">
        <v-row>
          <v-col cols="12" sm="12">
            <v-data-table
              :headers="headers"
              :items="body"
              :height="430"
              dense
              no-results-text="No se encontraron datos"
              no-data-text="No se encontraron datos"
              :search="search"
              class="border"
              :hide-default-footer="true"
              disable-pagination
              :fixed-header="true"
            >
              <template v-slot:top>
                <div class="w-100 d-flex justify-space-between align-center pa-2">
                  <div class="black--text pt-1">ENCUESTAS ASIGNADAS</div>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar"
                    class="mt-0 text-field-search-matriz"
                    background-color="rgb(220, 220, 220, 0.16)"
                    rounded
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.accion`]="{ item }">
                <v-col cols="12" class="d-flex justify-start pa-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab icon x-small color="primary" v-bind="attrs" v-on="on" v-on:click="responseQuiz(item)">
                        <v-icon small dark>
                          mdi-file-document-edit
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Responder</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        icon
                        v-if="item.counter > 0"
                        x-small
                        color="teal lighten-1"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="verRespuestas(item)"
                      >
                        <v-icon small dark>
                          mdi-clipboard-list
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver respuestas</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        icon
                        v-if="item.counter > 0"
                        x-small
                        color="deep-orange lighten-1"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="verRespuestasResumen(item)"
                      >
                        <v-icon small dark>
                          mdi-chart-box-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver resumen respuestas</span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <RespuestasEncuesta
          :survey="itemSeleccionado"
          :typesQuestion="typesQuestion"
          :dialog="dialog"
          @cerrarDialog="closeDialog"
        ></RespuestasEncuesta>

        <ResumenRespuestasEncuesta
          :survey="itemSeleccionadoResumen"
          :typesQuestion="typesQuestion"
          :dialog="dialogResumen"
          @cerrarDialog="closeDialogResumen"
        ></ResumenRespuestasEncuesta>
      </v-card>

      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import ResponderEncuestaService from '../services/ResponderEncuestaService';
import RespuestasEncuesta from '../components/RespuestasEncuesta';
import ResumenRespuestasEncuesta from '../components/ResumenRespuestasEncuesta';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  name: 'MatrizEncuestasAsignadas',
  components: {
    RespuestasEncuesta,
    ResumenRespuestasEncuesta
  },
  data: () => ({
    headers: [
      { text: 'ACCIONES', value: 'accion', align: 'center', width: '120px', class: 'grey lighten-3' },
      { text: 'Titulo', value: 'title', width: '300px', class: 'grey lighten-3' },
      { text: 'Asignado', value: 'assignedToFullName', width: '300px', class: 'grey lighten-3' },
      { text: 'Campaña', value: 'campaign', width: '200px', class: 'grey lighten-3' },
      { text: 'Cantidad', value: 'counterTotal', width: '120px', class: 'grey lighten-3' },
      { text: 'Fecha Inicio', value: 'startDate', width: '150px', class: 'grey lighten-3' },
      { text: 'Fecha Fin', value: 'endDate', width: '150px', class: 'grey lighten-3' }
    ],
    body: [],
    formatoEncuestaService: null,
    itemSeleccionado: null,
    itemSeleccionadoResumen: null,
    typesQuestion: [],
    dialog: false,
    dialogResumen: false,
    showLoading: false,
    search: ''
  }),
  methods: {
    async initialize() {
      this.showLoading = true;
      const res = await this.responderEncuestaService.get().execResource('listarEncuestasAsignadas');
      if (res.status) {
        this.body = res.data;
        this.body = this.body.map((encuesta) => {
          encuesta = {
            ...encuesta,
            counterTotal: encuesta.counter + '/' + encuesta.total,
            startDate: moment(encuesta.startDate).format('YYYY-MM-DD'),
            endDate: moment(encuesta.endDate).format('YYYY-MM-DD')
          };
          return encuesta;
        });
      }
      this.showLoading = false;
    },
    async responseQuiz(item) {
      const fechaInicio = new Date(item.startDate);
      const fechaFin = new Date(item.endDate);
      const fechaActual = new Date();
      if (fechaInicio > fechaActual) {
        await this.alertDialog('error', 'La encuesta aun no inicia, no puede ingresar respuestas');
      } else if (fechaFin < fechaActual) {
        await this.alertDialog('error', 'La encuesta ya terminó, no puede ingresar respuestas');
      } else this.$router.push({ path: `responder_encuesta/${item.userQuizId}` });
    },
    async verRespuestas(item) {
      this.dialogResumen = false;
      this.dialog = false;
      this.showLoading = true;

      const res = await this.responderEncuestaService.get().execResource('tiposPregunta');
      if (res.status) {
        this.typesQuestion = res.data;
      }

      const resQuiz = await this.responderEncuestaService.get().execResource('listarRespuestasEncuesta', {
        user_quiz_id: item.userQuizId
      });
      if (resQuiz.status) {
        this.itemSeleccionado = resQuiz.data;
        this.$nextTick(() => {
          this.dialog = true;
        });
      } else {
        await this.alertDialog('error', resQuiz.data);
      }
      this.showLoading = false;
    },
    async verRespuestasResumen(item) {
      this.dialogResumen = false;
      this.dialog = false;
      this.showLoading = true;

      const res = await this.responderEncuestaService.get().execResource('tiposPregunta');
      if (res.status) {
        this.typesQuestion = res.data;
      }

      const resQuiz = await this.responderEncuestaService.get().execResource('listaAnaliticasEncuesta', {
        user_quiz_id: item.userQuizId
      });
      if (resQuiz.status) {
        this.itemSeleccionadoResumen = resQuiz.data;
        console.log('data: ' + this.itemSeleccionadoResumen);
        this.$nextTick(() => {
          this.dialog = false;
          this.dialogResumen = true;
        });
      } else {
        await this.alertDialog('error', resQuiz.data);
      }

      this.showLoading = false;
    },
    closeDialog() {
      this.itemSeleccionado = null;
      this.dialog = false;
    },
    closeDialogResumen() {
      this.itemSeleccionadoResumen = null;
      this.dialogResumen = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    }
  },
  created() {
    this.responderEncuestaService = this.$httpService(new ResponderEncuestaService(), this);
    this.initialize();
  }
};
</script>

<style></style>
