<template>
  <div>
    <v-card class="mb-5" :ripple="false" :shaped="false" v-if="question.status === true">
      <v-card-text class="pb-4">
        <v-row>
          <v-col cols="12" sm="12" md="12" class="pb-0">
            <h2 class="black--text">
              {{ question.title }} <span class="ml-1 red--text" v-if="question.required">*</span>
            </h2>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="pb-0" v-if="typeSelected">
            <div class="text-end" v-if="typeSelected.multiOptions === true && typeSelected.value === 'v-radio-group'">
              <v-radio-group
                v-model="question.optionsResponse[0]"
                :rules="question.required ? responseRules : withoutRules"
                :disabled="disabled"
                :value="question.optionsResponse[0]"
              >
                <div v-for="(option, i) in question.optionsConvert" :key="i" class="d-flex">
                  <v-radio class="pt-1" v-bind:key="option.id" :label="option.title" :value="option.id"></v-radio>
                  <v-text-field
                    v-if="option.hasComment"
                    class="my-0 py-0 ml-6"
                    dense
                    label="Respuesta"
                    :rules="
                      question.required && question.optionsResponse[0] === option.id ? responseRules : withoutRules
                    "
                    single-line
                    v-model="question.comment"
                    :disabled="disabled"
                  ></v-text-field>
                </div>
              </v-radio-group>
              <a v-if="!disabled && question.optionsResponse[0]" v-on:click="borrarSeleccion">Borrar Selección</a>
            </div>
            <div align-self="center">
              <v-row
                v-if="typeSelected.multiOptions === true && typeSelected.value === 'v-radio-group-horizontal'"
                justify="center"
              >
                <div class="mt-9">
                  {{ question.optionsConvert[0].title }}
                </div>
                <div>
                  <v-radio-group
                    v-model="question.optionsResponse[0]"
                    :rules="question.required ? responseRules : withoutRules"
                    :disabled="disabled"
                    :value="question.optionsResponse[0]"
                    row
                  >
                    <div v-for="(optionQuestion, i) in question.optionsConvert" :key="i">
                      <v-radio
                        :label="optionQuestion.position + ''"
                        :value="!disabled ? optionQuestion.id : optionQuestion.position + ''"
                        class="radio-label-top mx-3"
                      ></v-radio>
                    </div>
                  </v-radio-group>
                </div>
                <div class="mt-9">
                  {{ question.optionsConvert[question.optionsConvert.length - 1].title }}
                </div>
              </v-row>
            </div>

            <div v-if="typeSelected.multiOptions === true && typeSelected.value === 'v-checkbox'">
              <div>
                <div v-for="(option, i) in question.optionsConvert" :key="i" class="d-flex">
                  <v-checkbox
                    class="mt-1"
                    v-model="question.optionsResponse"
                    :label="option.title"
                    :value="option"
                    hide-details
                    v-bind:key="option.title"
                    :disabled="disabled"
                  ></v-checkbox>
                  <v-text-field
                    v-if="option.hasComment"
                    class="my-0 py-0 ml-6 mt-1"
                    dense
                    label="Respuesta"
                    :rules="
                      question.required && question.optionsResponse.findIndex((el) => el.id === option.id) > -1
                        ? responseRules
                        : withoutRules
                    "
                    single-line
                    v-model="question.comment"
                    :disabled="disabled"
                  ></v-text-field>
                </div>
              </div>
              <div class="v-text-field__details mt-2" v-if="checkboxRules && question.required">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">La respuesta es obligatoria</div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="typeSelected.multiOptions === true && typeSelected.value === 'v-select'">
              <v-select
                v-model="question.optionsResponse[0]"
                :items="question.optionsConvert"
                :rules="question.required ? responseRules : withoutRules"
                item-text="title"
                label="Select"
                persistent-hint
                return-object
                single-line
                :disabled="disabled"
              ></v-select>
            </div>

            <div v-if="typeSelected.multiOptions === false && typeSelected.value === 'v-text-field'">
              <v-text-field
                class="my-0 py-0"
                label="Respuesta"
                :rules="question.required ? responseRules : withoutRules"
                single-line
                v-model="question.optionsResponse[0]"
                :disabled="disabled"
              ></v-text-field>
            </div>

            <div v-if="typeSelected.multiOptions === false && typeSelected.value === 'v-textarea'">
              <v-textarea
                name="input-7-1"
                label="Respuesta"
                :rules="question.required ? responseRules : withoutRules"
                v-model="question.optionsResponse[0]"
                rows="1"
                :disabled="disabled"
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ResponderEncuestaService from '../services/ResponderEncuestaService';

export default {
  name: 'PreguntaEncuesta',
  props: {
    position: {
      type: Number,
      default: 1
    },
    number: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    question: {
      type: Object,
      default: null
    },
    idSection: {
      type: String,
      default: null
    },
    posSection: {
      type: Number,
      default: null
    },
    typesQuestion: {
      type: Array,
      default: () => []
    },
    valid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    responderEncuestaService: null,
    withoutRules: [() => true],
    responseRules: [(v) => !!v || 'La respuesta es obligatoria']
  }),
  computed: {
    questionSelected() {
      let idSeleccionado = this.$store.state.selection.id;
      let tipoSeleccion = this.$store.state.selection.type;
      return tipoSeleccion == 'Q' ? idSeleccionado : null;
    },
    typeSelected() {
      let type = this.typesQuestion.find((el) => el.id === this.question.type);
      return type;
    },
    checkboxRules() {
      if (this.question.optionsResponse.length == 0 && this.question.required == true && this.valid == true) {
        return true;
      }
      return false;
    }
  },
  methods: {
    addOption() {
      this.$store.commit('ADD_OPTION_PREGUNTA', { posSection: this.posSection, posQuestion: this.position });
      let refOption = this.idSection + this.question.id + (this.question.options.length - 1);
      this.$nextTick(() => {
        this.$refs[refOption][0].focus();
        this.$refs[refOption][0].selectionStart = 0;
        this.$refs[refOption][0].selectionEnd = 10;
      });
    },
    borrarOpcion(id) {
      this.$store.dispatch('deleteElementoFormato', {
        type: 'O',
        id: id,
        idParent: this.question.id,
        surveyFormat: this.$store.state.surveyFormat,
        selection: this.$store.state.selection
      });
    },
    borrarSeleccion() {
      this.question.optionsResponse = [null];
    }
  },
  created() {
    this.responderEncuestaService = this.$httpService(new ResponderEncuestaService(), this);
  }
};
</script>
