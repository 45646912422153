<template>
  <div>
    <v-card class="mb-5">
      <v-card-text class="black--text">
        <v-row class="px-2">
          <v-col cols="12" class="py-0 pb-6 text-center">
            <h1>{{ surveyFormat.title }}</h1>
          </v-col>
          <v-col cols="12" class="py-0 pb-1">
            <span>{{ surveyFormat.description }}</span>
          </v-col>
          <v-col cols="12" class="py-0 pb-2 px-3 d-flex justify-space-between">
            <h3>CAMPAÑA: {{ surveyFormat.campaign }}</h3>
            <span>FECHA: {{ surveyFormat.startDate }} / {{ surveyFormat.endDate }}</span>
          </v-col>
        </v-row>
        <v-row v-if="!resume">
          <v-col cols="12" class="mb-2">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="py-0 pb-4">
            <h2>Datos Generales</h2>
          </v-col>
          <v-col cols="12" md="6" class="py-0 pb-2 pt-1">
            <v-select
              label="Sede"
              dense
              v-model="surveyFormat.headOfficeId"
              :rules="required"
              :disabled="disabled"
              :items="headOffices"
              item-text="text"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="mr-auto py-0 pb-2 pt-1">
            <v-select
              label="Unidad de Negocio"
              dense
              v-model="surveyFormat.businessUnitId"
              :rules="required"
              :disabled="disabled"
              :items="businessUnits"
              item-text="text"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0 pb-3">
            <h3>Encuestado</h3>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex py-0 pb-2">
            <v-switch
              v-model="surveyFormat.isAnonimous"
              :disabled="disabled"
              label="Anónimo"
              color="primary"
              class="mt-2"
              hide-details
            ></v-switch>
            <v-divider v-if="!surveyFormat.isAnonimous" vertical class="mx-6"></v-divider>
            <v-text-field
              v-if="!surveyFormat.isAnonimous"
              v-model="surveyFormat.employeeId"
              :rules="dniRules"
              :disabled="disabled"
              label="DNI"
              class="pt-0"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--<v-card class="mb-5">
      <v-card-text class="black--text">
        <v-row>
          <v-col cols="12" class="mb-2">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="py-0 pb-3">
            <h3>Encuestado</h3>
          </v-col>
          <v-col cols="12" sm="6" class="d-flex py-0 pb-2">
            <v-switch
              v-model="surveyFormat.isAnonimous"
              :disabled="disabled"
              label="Anónimo"
              color="primary"
              class="mt-2"
              hide-details
            ></v-switch>
            <v-divider v-if="!surveyFormat.isAnonimous" vertical class="mx-6"></v-divider>
            <v-text-field
              v-if="!surveyFormat.isAnonimous"
              v-model="surveyFormat.employeeId"
              :rules="dniRules"
              :disabled="disabled"
              label="DNI"
              class="pt-0"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text> </v-card
    >-->
  </div>
</template>

<script>
export default {
  name: 'DatosEncuesta',
  props: {
    surveyFormat: {
      type: Object,
      default: null
    },
    headOffices: {
      type: Array,
      default: new Array()
    },
    businessUnits: {
      type: Array,
      default: new Array()
    },
    disabled: {
      type: Boolean,
      default: false
    },
    resume: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    surveyFormatC() {
      return this.surveyFormat;
    }
  },
  data: () => ({
    dniRules: [(v) => (v && v.length === 8) || 'El dni debe tener 8 caracteres'],
    required: [(v) => (v && v.length > 0) || 'Campo requerido']
  })
};
</script>
