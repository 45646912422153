<template>
  <v-dialog v-model="dialog" scrollable max-width="1200px" hide-overlay transition="dialog-bottom-transition">
    <v-card v-if="survey">
      <v-card-title>
        <div class="d-flex justify-space-between" style="width: 100%;">
          <div class="d-flex align-left mt-2">
            <v-btn icon @click="cerrarDialog" class="mr-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3>{{ count }} Respuestas</h3>
          </div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container fluid class="pa-0 mt-3">
          <div class="px-5 mx-10">
            <v-row>
              <v-col cols="12">
                <v-form ref="form" v-model="validate" lazy-validation>
                  <datos-encuesta :surveyFormat="surveyFormat" :disabled="true" :resume="true"></datos-encuesta>
                  <resumen-seccion-encuesta
                    v-for="(section, i) in surveyFormat.sections"
                    :key="i"
                    :position="i"
                    :valid="validate"
                    :number="numberInActive(section.id)"
                    :total="sectionsActives.length"
                    :section="section"
                    :typesQuestion="typesQuestion"
                    :showFirstSection="surveyFormat.showFirstSection"
                    :disabled="false"
                  ></resumen-seccion-encuesta>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import ResponderEncuestaService from '../services/ResponderEncuestaService';
import DatosEncuesta from '@/gquest/encuesta/responder_encuesta/components/DatosEncuesta';
import ResumenSeccionEncuesta from '@/gquest/encuesta/responder_encuesta/components/ResumenSeccionEncuesta';

export default {
  name: 'ResumenRespuestasEncuesta',
  props: {
    dialogTable: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    survey: {
      type: Array,
      default: () => []
    },
    typesQuestion: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DatosEncuesta,
    ResumenSeccionEncuesta
  },
  computed: {
    surveyFormat() {
      return this.$store.state.surveyFormat;
    },
    sectionsActives() {
      return this.$store.state.surveyFormat.sections.filter((element) => element.status === true);
    }
  },
  watch: {
    survey(val) {
      this.posQuizSelected = 0;
      if (val !== null) {
        this.mostrarRespuestaPorPosicion();
      }
      return val;
    }
  },
  data: () => ({
    responderEncuestaService: null,
    responsesQuiz: [],
    validate: null,
    showLoading: false,
    response: null,
    count: null,
    total: null
  }),
  methods: {
    cerrarDialog() {
      this.$emit('cerrarDialog');
    },
    mostrarRespuestaPorPosicion() {
      let surveyFormat = this.survey[this.posQuizSelected].quiz[0];
      this.count = this.survey[0].counter;
      this.total = this.survey[0].total;
      let showFirstSection = surveyFormat.sections[0].title === '' ? false : true;
      surveyFormat = {
        ...surveyFormat,
        showFirstSection: showFirstSection
      };

      /*let showFirstSection = surveyFormat.sections[0].title === '' ? false : true;
      surveyFormat = {
        ...surveyFormat,
        showFirstSection: showFirstSection,
        employeeId: this.survey[0].employeeId,
        isAnonimous: this.survey[0].employeeId ? false : true
      };
      surveyFormat.sections = surveyFormat.sections.map((section) => {
        section.questions = section.questions.map((question) => {
          let optionsConvert = [];
          if (Object.prototype.hasOwnProperty.call(question, 'options')) {
            optionsConvert = question.options;
            optionsConvert = optionsConvert.map((option) => {
              return { id: option.id, title: option.title };
            });
          }
          let optionsResponse = question.answer.map((option) => {
            let typeSelected = this.typeSelected(question.type);
            if (typeSelected.value === 'v-radio-group') {
              return option.id;
            } else if (typeSelected.value === 'v-select' || typeSelected.value === 'v-checkbox') {
              return option;
            }
            return option.title;
          });

          question = { ...question, optionsResponse: optionsResponse, response: '', optionsConvert: optionsConvert };
          return question;
        });
        return section;
      }); */

      this.$store.commit('SET_FORMATO_ENCUESTA', surveyFormat);
      this.mostrarEncuesta = true;
    },
    numberInActive(id) {
      let number;
      this.sectionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    },
    typeSelected(id) {
      let type = this.typesQuestion.find((el) => el.id === id);
      return type;
    }
  },
  async created() {
    this.responderEncuestaService = this.$httpService(new ResponderEncuestaService(), this);
    if (this.survey !== null) {
      this.mostrarRespuestaPorPosicion();
    }
  }
};
</script>
