<template>
  <v-dialog v-model="dialog" scrollable max-width="1200px" hide-overlay transition="dialog-bottom-transition">
    <v-card v-if="survey">
      <v-card-title class="py-0">
        <div class="d-flex justify-space-between" style="width: 100%;">
          <div class="d-flex align-left mt-2">
            <v-btn icon @click="cerrarDialog" class="mr-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3>N° Encuesta / Respondidas ( {{ posQuizSelected + 1 }} / {{ count }} ) - Total: {{ total }}</h3>
          </div>
          <div class="d-flex align-right">
            <v-btn v-on:click="anterior" :disabled="posQuizSelected > 0 ? false : true" color="primary" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn v-on:click="siguiente" :disabled="posQuizSelected < count - 1 ? false : true" color="primary" icon>
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <!--<div style="position: fixed; width: 90%; z-index: 2; background-color: white;">
        <v-card-title style="width: 100%;">
          <div class="d-flex justify-space-between" style="width: 100%;">
            <div class="d-flex align-left mt-2">
              <h3>N° Encuesta / Respondidas ( {{ posQuizSelected + 1 }} / {{ count }} ) - Total: {{ total }}</h3>
            </div>
            <div class="d-flex align-right">
              <v-btn v-on:click="anterior" :disabled="posQuizSelected > 0 ? false : true" color="primary" icon>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn v-on:click="siguiente" :disabled="posQuizSelected < count - 1 ? false : true" color="primary" icon>
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-divider></v-divider>
      </div>-->
      <v-card-text>
        <v-container fluid class="pa-0 mt-3">
          <div class="px-5 mx-10">
            <v-row>
              <v-col cols="12">
                <v-form ref="form" v-model="validate" lazy-validation>
                  <datos-encuesta
                    :surveyFormat="surveyFormat"
                    :headOffices="headOffices"
                    :businessUnits="businessUnits"
                    :disabled="true"
                  ></datos-encuesta>
                  <seccion-encuesta
                    v-for="(section, i) in surveyFormat.sections"
                    :key="i"
                    :position="i"
                    :valid="validate"
                    :number="numberInActive(section.id)"
                    :total="sectionsActives.length"
                    :section="section"
                    :typesQuestion="typesQuestion"
                    :showFirstSection="surveyFormat.showFirstSection"
                    :disabled="true"
                  ></seccion-encuesta>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import ResponderEncuestaService from '../services/ResponderEncuestaService';
import DatosEncuesta from '@/gquest/encuesta/responder_encuesta/components/DatosEncuesta';
import SeccionEncuesta from '@/gquest/encuesta/responder_encuesta/components/SeccionEncuesta';

export default {
  name: 'RespuestasEncuesta',
  props: {
    dialogTable: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    survey: {
      type: Array,
      default: () => []
    },
    typesQuestion: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DatosEncuesta,
    SeccionEncuesta
  },
  computed: {
    surveyFormat() {
      return this.$store.state.surveyFormat;
    },
    sectionsActives() {
      return this.$store.state.surveyFormat.sections.filter((element) => element.status === true);
    }
  },
  watch: {
    survey(val) {
      this.posQuizSelected = 0;
      if (val !== null) {
        this.mostrarRespuestaPorPosicion();
      }
      return val;
    }
  },
  data: () => ({
    responderEncuestaService: null,
    responsesQuiz: [],
    validate: null,
    showLoading: false,
    response: null,
    posQuizSelected: 0,
    count: null,
    total: null,
    headOffices: [],
    businessUnits: []
  }),
  methods: {
    cerrarDialog() {
      this.$emit('cerrarDialog');
    },
    async listHeadOffice() {
      const res = await this.responderEncuestaService.get().execResource('sedes');
      if (res.status) {
        this.headOffices = res.data;
      }
    },
    async listBusinessUnit() {
      const res = await this.responderEncuestaService.get().execResource('unidadesNegocio');
      if (res.status) {
        this.businessUnits = res.data;
      }
    },
    mostrarRespuestaPorPosicion() {
      let surveyFormat = this.survey[this.posQuizSelected].quiz[0];
      this.count = this.survey[0].counter;
      this.total = this.survey[0].total;

      let showFirstSection = surveyFormat.sections[0].title === '' ? false : true;
      surveyFormat = {
        ...surveyFormat,
        showFirstSection: showFirstSection,
        businessUnitId: this.survey[this.posQuizSelected].businessUnitId,
        headOfficeId: this.survey[this.posQuizSelected].headOfficeId,
        employeeId: this.survey[this.posQuizSelected].employeeId,
        isAnonimous: this.survey[this.posQuizSelected].employeeId ? false : true
      };
      surveyFormat.sections = surveyFormat.sections.map((section) => {
        section.questions = section.questions.map((question) => {
          let optionsConvert = [];
          let typeSelected = this.typeSelected(question.type);

          let comment = null;
          let optionsResponse = question.answer.map((option) => {
            comment = option.comment == '' ? comment : option.comment;
            if (typeSelected.value === 'v-radio-group') {
              return option.id;
            } else if (typeSelected.value === 'v-select' || typeSelected.value === 'v-checkbox') {
              return option;
            }
            return option.title;
          });

          if (Object.prototype.hasOwnProperty.call(question, 'options')) {
            optionsConvert = question.options;
            optionsConvert = optionsConvert.map((option) => {
              if (typeSelected.value === 'v-checkbox') {
                let optionResp = optionsResponse.find((el) => el.id == option.id);
                return {
                  id: option.id,
                  title: option.title,
                  comment: optionResp ? optionResp.comment : '',
                  hasComment: option.hasComment
                };
              } else
                return { id: option.id, title: option.title, position: option.position, hasComment: option.hasComment };
            });

            if (typeSelected.value == 'v-checkbox') {
              optionsResponse = optionsResponse.map((option) => {
                let optionConv = optionsConvert.find((el) => el.id == option.id);
                let hasComment = optionConv?.hasComment == true;
                return { ...option, hasComment: hasComment };
              });
            }
          }

          question = {
            ...question,
            optionsResponse: optionsResponse,
            response: '',
            optionsConvert: optionsConvert,
            comment: comment
          };
          return question;
        });
        return section;
      });
      this.$store.commit('SET_FORMATO_ENCUESTA', surveyFormat);
      this.mostrarEncuesta = true;
    },
    numberInActive(id) {
      let number;
      this.sectionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    },
    siguiente() {
      this.posQuizSelected++;
      this.mostrarRespuestaPorPosicion();
    },
    anterior() {
      this.posQuizSelected--;
      this.mostrarRespuestaPorPosicion();
    },
    typeSelected(id) {
      let type = this.typesQuestion.find((el) => el.id === id);
      return type;
    }
  },
  async created() {
    this.responderEncuestaService = this.$httpService(new ResponderEncuestaService(), this);
    await this.listHeadOffice();
    await this.listBusinessUnit();
    if (this.survey !== null) {
      this.mostrarRespuestaPorPosicion();
    }
  }
};
</script>
