class FormatoEncuestaService {
  constructor() {}
  resources = () => ({
    tiposPregunta: {
      uri: `/v1/types`,
      method: ['get']
    },
    sedes: {
      uri: `/v1/listheadoffice`,
      method: ['get']
    },
    unidadesNegocio: {
      uri: `/v1/listbusinessunit`,
      method: ['get']
    },
    obtenerEncuestaAsignada: {
      uri: `/v1/quizuserassignedto`,
      method: ['get']
    },
    listarEncuestasAsignadas: {
      uri: `/v1/listuserquiz`,
      method: ['get']
    },
    guardarRespuestasEncuesta: {
      uri: `/v1/answer`,
      method: ['post']
    },
    listarRespuestasEncuesta: {
      uri: `/v1/listanswer`,
      method: ['get']
    },
    listaAnaliticasEncuesta: {
      uri: `/v1/listanalytics`,
      method: ['get']
    }
  });
}
export default FormatoEncuestaService;
