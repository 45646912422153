<template>
  <div>
    <div v-if="(showFirstSection || totalsections > 1) && section !== null" :class="!section.questions ? 'mb-4' : ''">
      <div class="header-section pa-2 pl-5" color="primary" style="width: 100%;">
        {{ section.title }}
      </div>
      <v-card class="mb-5" v-if="section.description">
        <v-card-text>
          <span> {{ section.description }} </span>
        </v-card-text>
      </v-card>
    </div>
    <resumen-pregunta-encuesta
      v-for="(question, i) in section.questions"
      :key="i"
      :number="numberInActive(question.id)"
      :total="section.questions.length"
      :position="i"
      :question="question"
      :idSection="section.id"
      :posSection="position"
      :typesQuestion="typesQuestion"
      :valid="valid"
      :disabled="disabled"
      :questionDetails="{ question: question, typesQuestion: typesQuestion }"
    >
    </resumen-pregunta-encuesta>
  </div>
</template>

<script>
import ResumenPreguntaEncuesta from './ResumenPreguntaEncuesta';

export default {
  name: 'ResumenSeccionEncuesta',
  components: {
    ResumenPreguntaEncuesta
  },
  props: {
    position: {
      type: Number,
      default: 1
    },
    number: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    section: {
      type: Object,
      default: null
    },
    showFirstSection: {
      type: Boolean,
      default: false
    },
    typesQuestion: {
      type: Array,
      default: () => []
    },
    valid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalsections() {
      return this.total;
    },
    sectionSelected() {
      let idSeleccionado = this.$store.state.selection.id;
      let tipoSeleccion = this.$store.state.selection.type;
      return tipoSeleccion == 'S' ? idSeleccionado : null;
    },
    questionsActives() {
      return this.section.questions.filter((element) => element.status === true);
    }
  },
  data: () => ({
    tituloSeccion: '',
    descripcionSeccion: '',
    items: [{ title: 'Borrar' }]
  }),
  methods: {
    numberInActive(id) {
      let number;
      this.questionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    }
  }
};
</script>
