<template>
  <div>
    <v-card class="mb-5" :ripple="false" :shaped="false">
      <v-card-text class="pb-4">
        <v-row>
          <v-col cols="12" sm="12" md="12" class="pb-0">
            <h2 class="black--text">
              {{ question.title }} <span class="ml-1 red--text" v-if="question.required">*</span>
            </h2>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="typeChart">
          <vue-apex-charts
            ref="vueApexChart"
            :type="typeChart"
            height="350"
            :width="typeSelected.value == 'v-select' ? '1000' : '100%'"
            :options="options"
            :series="series"
          ></vue-apex-charts>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ResponderEncuestaService from '../services/ResponderEncuestaService';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ResumenPreguntaEncuesta',
  props: {
    position: {
      type: Number,
      default: 1
    },
    number: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    question: {
      type: Object,
      default: () => {}
    },
    idSection: {
      type: String,
      default: null
    },
    posSection: {
      type: Number,
      default: null
    },
    typesQuestion: {
      type: Array,
      default: () => []
    },
    valid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueApexCharts
  },
  data: () => ({
    responderEncuestaService: null,
    withoutRules: [() => true],
    responseRules: [(v) => !!v || 'La respuesta es obligatoria'],
    typeChart: null,
    options: {},
    optionsPie: {
      labels: [],
      chart: {
        width: '400',
        type: 'pie',
        stacked: true,
        toolbar: {
          show: true
        },
        sparkline: {
          enabled: false
        }
      },
      title: {
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'right',
        fontSize: '12px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          vertical: 15
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      grid: {
        padding: {
          left: 0,
          right: 0
        },
        borderColor: 'rgba(0,0,0,0.5)',
        strokeDashArray: 3,
        show: true
      },
      plotOptions: {
        pie: {
          customScale: 1.0,
          endingShape: 'flat'
        }
      },
      responsive: []
    },
    optionsBar: {
      grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, .3)',
        strokeDashArray: 3,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '48%',
          endingShape: 'flat'
        }
      },
      colors: ['#02a99e'],
      fill: {
        type: 'solid',
        opacity: 1
      },
      chart: {
        width: '400',
        stacked: true,
        toolbar: {
          show: true
        },
        sparkline: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true /*,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }*/
      },
      markers: {
        size: 0
      },
      title: {
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      xaxis: {
        type: 'category',
        categories: [],
        labels: {
          trim: true,
          style: {
            cssClass: 'text-muted fill-color'
          },
          name: {
            show: true,
            fontSize: ''
          }
        }
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      },
      stroke: {
        curve: 'straight',
        width: 0
      },
      tooltip: {
        theme: 'dark'
      }
    },
    series: []
  }),
  watch: {
    question(val) {
      console.log(val);
      let typeSelected = this.typesQuestion.find((el) => el.id === val.type);
      if (
        typeSelected.value == 'v-radio-group' ||
        typeSelected.value == 'v-checkbox' ||
        typeSelected.value == 'v-text-field' ||
        typeSelected.value == 'v-textarea'
      ) {
        this.options = {
          ...this.optionsBar,
          xaxis: {
            type: 'category',
            categories: val.options,
            labels: {
              trim: true,
              style: {
                fontSize: '10px',
                fontWeight: 'bolder',
                cssClass: 'text-muted fill-color'
              },
              name: {
                show: true,
                fontSize: ''
              }
            }
          }
        };
        this.typeChart = 'bar';
        this.series = [
          {
            data: val.count
          }
        ];
        //this.options.xaxis.categories = val.options;
      } else {
        this.options = {
          ...this.optionsPie,
          labels: val.options
        };
        this.typeChart = 'pie';
        this.series = val.count;
        //this.options = this.optionsPie;
        //this.options.labels = this.question.options;
      }

      if (typeSelected.value == 'v-text-field' || typeSelected.value == 'v-textarea') {
        this.typeChart = 'bar';
        this.options.plotOptions.bar.horizontal = false;
      }
      this.responderEncuestaService = this.$httpService(new ResponderEncuestaService(), this);
    }
  },
  computed: {
    questionSelected() {
      let idSeleccionado = this.$store.state.selection.id;
      let tipoSeleccion = this.$store.state.selection.type;
      return tipoSeleccion == 'Q' ? idSeleccionado : null;
    },
    typeSelected() {
      let type = this.typesQuestion.find((el) => el.id === this.question.type);
      return type;
    },
    checkboxRules() {
      if (this.question.optionsResponse.length == 0 && this.question.required == true && this.valid == true) {
        return true;
      }
      return false;
    }
  },
  methods: {
    addOption() {
      this.$store.commit('ADD_OPTION_PREGUNTA', { posSection: this.posSection, posQuestion: this.position });
      let refOption = this.idSection + this.question.id + (this.question.options.length - 1);
      this.$nextTick(() => {
        this.$refs[refOption][0].focus();
        this.$refs[refOption][0].selectionStart = 0;
        this.$refs[refOption][0].selectionEnd = 10;
      });
    },
    borrarOpcion(id) {
      this.$store.dispatch('deleteElementoFormato', {
        type: 'O',
        id: id,
        idParent: this.question.id,
        surveyFormat: this.$store.state.surveyFormat,
        selection: this.$store.state.selection
      });
    }
  },
  async created() {
    if (
      this.typeSelected.value == 'v-radio-group' ||
      this.typeSelected.value == 'v-checkbox' ||
      this.typeSelected.value == 'v-text-field' ||
      this.typeSelected.value == 'v-textarea'
    ) {
      this.options = {
        ...this.optionsBar,
        xaxis: {
          type: 'category',
          categories: this.question.options,
          labels: {
            trim: true,
            style: {
              fontSize: '10px',
              fontWeight: 'bolder',
              cssClass: 'text-muted fill-color'
            },
            name: {
              show: true,
              fontSize: ''
            }
          }
        }
      };
      this.typeChart = 'bar';
      this.series = [
        {
          data: this.question.count
        }
      ];
      //this.options.xaxis.categories = this.question.options;
    } else {
      if (this.typeSelected.value == 'v-radio-group-horizontal') {
        //let text = '';
        /*this.question.options.forEach(element => {
          element.
        });*/
        console.log(this.question.options);
        this.options = {
          ...this.optionsPie,
          labels: this.question.options,
          title: {
            ...this.optionsPie.title
            //text: '-----'
          },
          legend: {
            ...this.optionsPie.legend,
            position: 'top'
          }
        };
      } else {
        this.options = {
          ...this.optionsPie,
          labels: this.question.options
        };
      }
      this.typeChart = 'pie';
      this.series = this.question.count;
      //this.options = this.optionsPie;
      //this.options.labels = this.question.options;
    }

    if (this.typeSelected.value == 'v-text-field' || this.typeSelected.value == 'v-textarea') {
      this.options.plotOptions.bar.horizontal = false;
    }
    this.responderEncuestaService = this.$httpService(new ResponderEncuestaService(), this);
  }
};
</script>
